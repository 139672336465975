import type { PropsWithChildren } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Image from '@atlaskit/image';
import { token } from '@atlaskit/tokens';

import { Card } from '../UpsellLooknFeel/Card';

import PageInsightsGraph from './images/PageInsightsGraph.svg';
import PageInsightsGraphDark from './images/PageInsightsGraphDark.svg';

export const i18n = defineMessages({
	title: {
		id: 'change-edition.page-insights.title',
		defaultMessage: 'Peek into trends with page insights',
		description: 'Access page insights title',
	},
	body: {
		id: 'change-edition.page-insights.body',
		defaultMessage:
			'See how well your content performs by tracking page views when you upgrade to Confluence Standard.',
		description: 'Page insights body',
	},
	learnMoreLink: {
		id: 'change-edition.page-insights.learn-more-link',
		defaultMessage: 'Explore insights',
		description:
			'The text of the hyperlink which takes the user to a page explaining the feature Page insights',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageWrapper = styled.div({
	padding: `${token('space.200', '16px')} ${token('space.200', '16px')} ${token(
		'space.0',
		'0px',
	)} ${token('space.200', '16px')}`,
});

export const ChangeEditionSectionMessagePageInsights = ({
	children,
}: ChangeEditionSectionMessagePageInsightsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Card
			title={formatMessage(i18n.title)}
			body={
				<>
					{formatMessage(i18n.body)}
					{children}
				</>
			}
			learnMoreLink={{
				messageDescriptor: i18n.learnMoreLink,
				href: 'https://support.atlassian.com/confluence-cloud/docs/view-insights-on-pages/',
			}}
			source="pageInsights"
		>
			<ImageWrapper>
				<Image src={PageInsightsGraph} srcDark={PageInsightsGraphDark} alt="" />
			</ImageWrapper>
		</Card>
	);
};

type ChangeEditionSectionMessagePageInsightsProps = PropsWithChildren<{}>;

import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import ByLine from '@atlassian/analytics-byline';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { useSessionData } from '@confluence/session-data';
import {
	ANALYTICS_BYLINE_EXPERIENCE,
	ANALYTICS_DIALOG_EXPERIENCE,
	ExperienceTrackerContext,
	NOTIFY_ON_FIRST_VIEW_EXPERIENCE,
} from '@confluence/experience-tracker';
import { getMark } from '@confluence/performance';
import { SSR_PARTIAL_COMPONENT, SetSSRPartialSuccess } from '@confluence/ssr-utilities';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { expValEquals } from '@confluence/feature-experiments';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys,
} from '@confluence/storage-manager';

import { SSREvent, useHideByline } from '../../hooks';
import { CONTENT_ANALYTICS_DIALOG_METRIC } from '../../perf.config';
import type {
	ContentAnalyticsViewersUnifiedQuery as GraphqlQueryData,
	ContentAnalyticsViewersUnifiedQueryVariables as GraphqlQueryVariables,
} from '../../queries/__types__/ContentAnalyticsViewersUnifiedQuery';
import { NotifyUsersOnFirstViewMutation } from '../../mutations/NotifyUsersOnFirstViewMutation.graphql';
import { ContentAnalyticsViewersUnifiedQuery as ViewersQuery } from '../../queries/ContentAnalyticsViewersUnifiedQuery.graphql';
import { LazyAnalyticsDialog } from '../AnalyticsDialog';
import { DialogPillName } from '../AnalyticsDialog/constants';
import { getViewersCount } from '../utils';

import { CLICKED_BYLINE_SSR_MARK, TestId } from './constants';
import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';

export const useAnalyticsByLine: UseAnalyticsByLineHook = ({
	contentId,
	skip,
	wasComponentSSRed,
}) => {
	const isLivePage = useIsLivePage();
	const [selectedPill, onPillSelected] = useState<DialogPillName>(DialogPillName.VIEWS);
	const [dialogReferenceDate, setDialogReferenceDate] = useState(new Date());

	const [shouldRefetch, setShouldRefetch] = useState(false);
	const triggerRefetch = useCallback(() => {
		setShouldRefetch(true);
	}, [setShouldRefetch]);

	useEffect(() => {
		setDialogReferenceDate(new Date());
		setShouldRefetch(false);
		onPillSelected(DialogPillName.VIEWS);
	}, [
		contentId,
		// Preserved for compatibility with the AnalyticsByLine implementation
		// before this source code was moved from there to here:
		wasComponentSSRed,
	]);

	const { edition } = useSessionData();
	const { locale } = useIntl();
	const isEnglishLocale = locale.toLowerCase().startsWith('en');

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { data, loading, error, refetch } = useQuery<GraphqlQueryData, GraphqlQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ViewersQuery,
		{
			variables: {
				contentId,
			},
			context: {
				single: true,
			},
			skip,
		},
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// mutations sends request to BE to notify users of content first view
	// content owner will receive notification on first view in bell icon
	const [notifyUsersOnFirstView, { error: notifyUsersOnFirstViewError }] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		NotifyUsersOnFirstViewMutation,
	);

	const notifyUsersOnFirstViewMutationStorageKey = `${keys.NOTIFY_USERS_ON_FIRST_VIEW_MUTATION_FIRED}/content/${contentId}`;

	const handleNotify = useCallback(async () => {
		experienceTracker.start({ name: NOTIFY_ON_FIRST_VIEW_EXPERIENCE });
		try {
			await notifyUsersOnFirstView({ variables: { input: contentId } });

			experienceTracker.succeed({ name: NOTIFY_ON_FIRST_VIEW_EXPERIENCE });

			sessionStorage.setItem(notifyUsersOnFirstViewMutationStorageKey, true);

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'succeeded',
					actionSubject: 'FirstViewNotification',
					source: 'viewPageScreen',
					objectId: contentId,
					attributes: {
						contentId,
					},
				},
			}).fire();
		} catch (err) {
			// handle error
			experienceTracker.fail({
				error: err || new Error('An unknown error has occurred'),
				name: NOTIFY_ON_FIRST_VIEW_EXPERIENCE,
			});
		}
	}, [
		contentId,
		createAnalyticsEvent,
		experienceTracker,
		notifyUsersOnFirstView,
		notifyUsersOnFirstViewMutationStorageKey,
	]);

	// We want to allow the data to refresh once upon opening the dialog for the first time, but never
	// beyond that. This one-time refresh exists so that the data displayed in the dialog matches the
	// data being displayed in the byline.
	const hasRefetched = useRef(false);

	useEffect(() => {
		if (shouldRefetch && !hasRefetched.current) {
			void refetch({ contentId });

			hasRefetched.current = true;
		} else if (!shouldRefetch && hasRefetched.current) {
			hasRefetched.current = false;
		}
	}, [contentId, refetch, shouldRefetch]);

	const hideByLine = useHideByline(loading, data, error);

	const count = getViewersCount(data);

	// Trigger notification if 1 page view, paid editions, english locale, & experiment enabled
	useEffect(() => {
		if (
			count === 1 &&
			// when user publish page without hard-reload page, the page viewer count doesn't get updated
			// adding this check to avoid duplicate calls made to complement `count === 1` check to avoid unnecessary duplicate calls in `handleNotify`
			!sessionStorage.doesContain(notifyUsersOnFirstViewMutationStorageKey) &&
			edition !== ConfluenceEdition.FREE &&
			isEnglishLocale &&
			expValEquals('cc_onboarding_notify_first_page_view_v2', 'cohort', 'experiment')
		) {
			void handleNotify();
		}
	}, [count, handleNotify, edition, isEnglishLocale, notifyUsersOnFirstViewMutationStorageKey]);

	const onPopupTriggerSSREvent = useCallback(
		(event: SSREvent) => {
			switch (event) {
				case SSREvent.CLICK:
					CONTENT_ANALYTICS_DIALOG_METRIC.start({
						startTime: getMark(CLICKED_BYLINE_SSR_MARK),
					});
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'AnalyticsButton',
							source: 'viewPageScreen',
							objectId: contentId,
							attributes: {
								from: 'fromByline',
								isSSR: wasComponentSSRed,
								isLivePage,
							},
						},
					}).fire();
					break;

				case SSREvent.FOCUS:
				case SSREvent.HOVER:
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'hovered',
							actionSubject: 'AnalyticsButton',
							source: 'page',
							objectId: contentId,
							attributes: {
								from: 'fromByline',
								isSSR: wasComponentSSRed,
								tabFocus: event === SSREvent.FOCUS,
								isLivePage,
							},
						},
					}).fire();
					break;
			}
		},
		[contentId, createAnalyticsEvent, wasComponentSSRed, isLivePage],
	);

	return {
		error: error ?? notifyUsersOnFirstViewError,
		loading,

		//
		// Popup content
		//

		popupContent: ({ onClose, zIndex }) => (
			<LazyAnalyticsDialog
				contentId={contentId}
				onClose={onClose}
				referenceDate={dialogReferenceDate}
				selectedPill={selectedPill}
				onPillSelected={onPillSelected}
				requestBylineCountRefetch={triggerRefetch}
				zIndex={zIndex}
			/>
		),

		popupContentExperience: ANALYTICS_DIALOG_EXPERIENCE,

		onPopupContentClose: () => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'dismissed',
					actionSubject: 'analyticsPageModal',
					actionSubjectId: 'analytics',
					objectId: contentId,
					source: 'analyticsPageModal',
				},
			}).fire();
		},

		//
		// Popup trigger
		//

		popupTrigger: ({ onClick }) =>
			hideByLine || (
				<>
					<ByLine
						count={count}
						onClick={onClick}
						isBylineComponent
						abbreviatePageViews={
							isLivePage ||
							expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
						}
						testId={TestId.BYLINE}
					/>
					<SetSSRPartialSuccess name={SSR_PARTIAL_COMPONENT.AnalyticsByLine} />
				</>
			),

		popupTriggerExperience: ANALYTICS_BYLINE_EXPERIENCE,

		onPopupTriggerSSREvent,
	};
};
